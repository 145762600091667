<template>
  <div class="ant-tables ant-glass-background ant-border-top radius-0">
    <dynamic-data-table
      :can-edit="selectedTable ? selectedTable.permissions.update : false"
      :is-loading="tableLoading"
      :project-id="selectedTable ? selectedTable.project : undefined"
      :table-columns="tableColumns"
      :table-headers="tableHeaders"
      :table-id="selectedTable ? selectedTable.id : undefined"
      :table-records="filterTableRows"
      :table-title="selectedTable ? selectedTable.name : '-'"
    >
      <template #table-actions>
        <v-autocomplete
          :items="sortedAntTables"
          :value="selectedTable"
          clearable
          hide-details
          item-text="name"
          item-value="id"
          label="Table"
          menu-props="auto"
          prepend-icon="mdi-table-multiple"
          return-object
          single-line
          style="max-width: 300px"
          @change="selectTable"
        />
      </template>
      <template
        v-for="(header, index) in tableHeaders.filter((tmp) => tmp.hasSlot)"
        #[`item.${header.value}`]="{ value, rowId, item }"
      >
        <div :key="header.value" class="editable-field">
          <v-edit-dialog
            v-if="selectedTable ? selectedTable.permissions.update : false"
            :return-value.sync="item[header.value]"
            large
            @save="save(item.id, header.value, item[header.value])"
          >
            <div class="field-content">
              <template v-if="tableColumns[index].type === 'link'">
                <div @click.stop="() => {}">
                  <a :href="item[header.value]" target="_blank">
                    {{ item[header.value] }}
                  </a>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] }}
              </template>
              <v-icon class="edit-icon" small> mdi-pencil</v-icon>
            </div>
            <template #input>
              <v-text-field
                v-model="item[header.value]"
                class="class2"
                label="Edit"
                single-line
              />
            </template>
          </v-edit-dialog>

          <div v-else class="field-content">
            <template v-if="tableColumns[index].type === 'link'">
              <div @click.stop="() => {}">
                <a :href="item[header.value]" target="_blank">
                  {{ item[header.value] }}
                </a>
              </div>
            </template>
            <template v-else>
              {{ item[header.value] }}
            </template>
          </div>
        </div>
      </template>
    </dynamic-data-table>
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';

export default {
  name: 'AntTables',
  components: { DynamicDataTable },
  props: {
    isOnView: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      tableStructureFilter: undefined,
      tableSbsFilter: undefined,
    };
  },
  computed: {
    ...mapGetters(['project', 'selectedSbsObject']),
    ...mapGetters('antTables', {
      antTables: 'antTables',
      selectedTable: 'selectedTable',
      tableLoading: 'tableLoading',
      tableHeaders: 'tableHeaders',
      tableColumns: 'tableColumns',
      tableRows: 'tableRows',
    }),
    sortedAntTables() {
      return [...this.antTables].sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
    filterTableRows() {
      if (!this.isOnView) return [];
      if (this.tableSbsFilter !== undefined) {
        let sbsColumnIndex = this.tableColumns.findIndex((column) => {
          return column.type === 'sbscode';
        });
        if (sbsColumnIndex !== -1) {
          // sbs column found
          return this.tableRows.filter((row) => {
            return (
              row[this.tableColumns[sbsColumnIndex].name] ===
              this.tableSbsFilter
            );
          });
        } else {
          this.$store.commit('showNotification', {
            content: `Sbs column not found in detail table`,
            color: 'info',
          });
          return this.tableRows;
        }
      } else {
        return this.tableRows;
      }
    },
  },
  watch: {
    selectedSbsObject(newObject, oldObject) {
      if (newObject) {
        this.tableSbsFilter = newObject.code;
      } else {
        this.tableSbsFilter = undefined;
      }
    },
  },
  mounted() {
    this.$store.dispatch('antTables/fetch');
  },
  methods: {
    selectTable(table) {
      this.$store.commit('antTables/select_table', table);
      this.$store.dispatch('antTables/getTableData');
    },
    save(recordId, column, value) {
      let body = {
        project: {
          id: this.selectedTable.project,
        },
        table: {
          id: this.selectedTable.id,
        },
        record: {
          [column]: value,
        },
      };

      this.$store.dispatch('antTables/updateRecord', { recordId, body });
    },
  },
};
</script>

<style lang="scss" scoped>
.ant-tables {
  height: 100%;

  > * {
    height: 100%;
  }
}

.editable-field {
  display: flex;

  ::v-deep .v-small-dialog__activator__content {
    display: flex;
    width: 100%;

    .field-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .edit-icon {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    .edit-icon {
      opacity: 1;
    }
  }
}
</style>
